import axiosInstance from "./AxiosInstance";

export async function getMerchant(merchantId) {
  const res = await axiosInstance.get(`/merchant/${merchantId}`);
  return res;
}

export async function updateMerchant(formData, Id) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  const res = await axiosInstance.post(`/merchant/${Id}`, formData, config);
  return res;
}
