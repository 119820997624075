import React, { lazy, useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { UpdateMenuItemAction } from "../../../../store/actions/MenuItemsActions";
import pic3 from "../../../../images/popular-img/pic-3.jpg";
import bgimage from "../../../../images/no-img-avatar.png";
import { useFetcher } from "react-router-dom";
import { useForm } from "react-hook-form";
import API_CONFIG from "../../../../apiConfig";

function EditItemModal(props) {
  const { modalToggle, showModal, editedItem, item } = props;
  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const menuItemsList = useSelector((state) => state.menuItems.menuItems.data);
  const baseUrl = API_CONFIG.baseUrl;

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [activeItem, setActiveItem] = useState(item);
  const [menuItemImage, setMenuItemImage] = useState(item.image);
  const [menuItemImagePreview, setmenuItemImagePreview] = useState(menuItemImage);
  const [menuItemTitle, setMenuItemTitle] = useState(item.title);
  const [menuItemDescription, setMenuItemDescription] = useState(item.description);
  const [menuItemPrice, setMenuItemPrice] = useState(item.price);
  const [menuItemActive, setMenuItemActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    setIsLoading(true);
    setActiveItem(item);
    setMenuItemImage(item.image);
    setMenuItemTitle(item.title);
    setMenuItemDescription(item.description);
    setMenuItemPrice(item.price);
    setIsLoading(false);
  }, [item]);

  useEffect(() => {
    editedItem(true);
  }, [menuItemImage]);

  const fileHandler = (e) => {
    const value = e.target.files[0];
    setMenuItemImage(value);

    // Preview image
    const blob = URL.createObjectURL(value);
    setmenuItemImagePreview(blob);
  };

  function RemoveImage() {
    removeImagePreview();
    removeItemImage();
  }

  function removeImagePreview() {
    return setmenuItemImagePreview(null);
  }

  function removeItemImage() {
    return setMenuItemImage(null);
  }

  function handleClose() {
    RemoveImage();
    modalToggle();
  }

  function handleSave() {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("merchant_id", merchantId);
    formData.append("title", menuItemTitle !== null ? menuItemTitle : activeItem.title);
    formData.append(
      "description",
      menuItemDescription !== null ? menuItemDescription : activeItem.description
    );
    formData.append("price", menuItemPrice !== null ? menuItemPrice : activeItem.price);
    formData.append("image", menuItemImage);
    formData.append(
      "active",
      menuItemActive !== null ? menuItemActive : activeItem.active
    );
    dispatch(UpdateMenuItemAction(formData, activeItem.id));
    editedItem(true);
    RemoveImage();
    modalToggle();
  }

  if (!isLoading) {
    return (
      <Modal className="modal fade" show={showModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Menu Item
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => handleClose()}
          ></button>
        </div>

        <div className="modal-body">
          <form onSubmit={handleSubmit(handleSave)}>
            {/* <div className="d-flex align-items-center active justify-content-center">
              <div className="form-check me-3 ">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("active")}
                  id="is-active"
                  value={item.active === "1" ? item.active : ""}
                  onChange={(e) => setMenuItemActive("1")}
                  checked={menuItemActive === "1"}
                />
                <label className="form-check-label" htmlFor="is-active">
                  Active
                </label>
              </div>
              <div className="form-check style-1">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("active")}
                  id="is-inactive"
                  value={item.active === "0" ? item.active : ""}
                  onChange={(e) => setMenuItemActive("0")}
                  checked={menuItemActive === "0"}
                />
                <label className="form-check-label" htmlFor="is-inactive">
                  Inactive
                </label>
              </div>
            </div> */}

            <div className="modal-inside">
              <p className="fs-18">Item Image</p>
              <div className="setting-img d-flex align-items-center mb-4">
                <div className="avatar-upload d-flex align-items-center">
                  <div className=" change position-relative d-flex">
                    <div className="avatar-preview">
                      <img
                        className="menu-item-image-file"
                        src={item.image ? `${baseUrl}/${item.image}` : bgimage}
                        alt={menuItemImage ? menuItemImage.name : "Menu Item Image"}
                      />
                    </div>
                    <div className="change-btn d-flex align-items-center flex-wrap">
                      <input
                        type="file"
                        {...register("image")}
                        {...register("image_preview")}
                        onChange={fileHandler}
                        className="form-control"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="imageUpload" className="dlab-upload">
                        Choose File
                      </label>
                      <button
                        type="button"
                        className="btn remove-img ms-2"
                        onClick={() => RemoveImage()}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <label htmlFor="exampleInputText" className="form-label">
                Item Name
              </label>
              <input
                type="text"
                value={menuItemTitle}
                {...register("title")}
                onChange={(e) => setMenuItemTitle(e.target.value)}
                className="form-control"
                id="exampleInputText"
              />
            </div>
            <div className="modal-inside">
              <label htmlFor="exampleInputText" className="form-label">
                Item Description
              </label>
              <textarea
                value={menuItemDescription}
                rows={5}
                cols={20}
                {...register("description")}
                onChange={(e) => setMenuItemDescription(e.target.value)}
                className="form-control h-25"
                id="exampleInputText"
              />
            </div>
            <div className="row">
              <div className="col-xl-4">
                <div className="modal-inside">
                  <label htmlFor="exampleInputnumber" className="form-label mb-2">
                    Item Price
                  </label>
                  <input
                    type="number"
                    value={menuItemPrice}
                    step="0.01"
                    {...register("price")}
                    onChange={(e) => setMenuItemPrice(e.target.value)}
                    className="form-control"
                    id="exampleInputnumber"
                  />
                </div>
              </div>
              <div className="col-xl-4">
                {/* <div className="modal-inside">
                    <label htmlFor="exampleInputnumber-1" className="form-label mb-2">
                      Delivery Charge
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="exampleInputnumber-1"
                    />
                  </div> */}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleClose()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default EditItemModal;
