import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { GetMerchantDataAction } from "../../../store/actions/MerchantActions";

import Orders from "./Orders/Orders";
import { Dropdown } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";

// const sparklinedata = [4, 7, 4, 9, 5, 6, 8, 3, 1, 3, 5, 6];

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "option1":
//       return (state = "Year");
//     //break;
//     case "option2":
//       return (state = "Month");
//     //break;
//     case "option3":
//       return (state = "Week");
//     //break;
//     default:
//       return state;
//   }
// };

function Dashboard() {
  const dispatch = useDispatch();
  // const [state, dispatch] = useReducer(reducer, "Year");
  const state = useSelector((state) => state);
  const merchantId = state.auth.auth.merchant_id;
  const orders = state.orders.orders.data;

  const [activeOrders, setActiveOrders] = useState(0);
  const [redeemedOrders, setRedeemedOrders] = useState(0);
  const [expiredOrders, setExpiredOrders] = useState(0);
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    dispatch(GetMerchantDataAction(merchantId));
  }, [merchantId]);

  useEffect(() => {
    setIsDashboard(true);
  }, []);

  useEffect(() => {
    let active = 0;
    if (orders) {
      orders.forEach((order) => {
        if (order.merchant_id === merchantId) {
          if (order.is_redeemed === 0) {
            active ++;
          }
        }
      }); 
    }
    setActiveOrders(active);
  }, []);

  useEffect(() => {
    let redeemed = 0;
    if (orders) {
      orders.forEach((order) => {
        if (order.merchant_id === merchantId) {
          if (order.is_redeemed === 1) {
            redeemed ++;
          }
        }
      });
    }
    setRedeemedOrders(redeemed);
  }, []);

  return (
    <>
      <div className="row">
        <PageTitle />
        <div className="col-lg-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card income-bx">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-8">
                      <p className="font-w500 mb-0">Total Revenue</p>
                      <h2 className="mb-0 text-primary">$12,890,00</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h4 className="cate-title mb-0">Orders</h4>
                    {/* <select className="form-control default-select style-1 w-auto border" >
                                      <option>Month</option>
                                      <option>This Month</option>
                                      <option>Last Month</option>
                                    </select> */}
                    {/* <Dropdown className="deliver-drop">
                                        <Dropdown.Toggle as="div" className="form-control ms-0 default-select border deliver-drop-toggle style2 i-false">
                                            {state} <i className="fas fa-chevron-down drop-toggle-icon" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option1'})}>Year</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option2'})}>Month</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>dispatch({type : 'option3'})}>Week</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {/* <div className="d-flex align-items-center flex md-nowrap flex-wrap mb-3 mb-md-0">
																						<div className="icon-bx bg-primary d-inline-block text-center me-3">
																								<svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path d="M20 8.5C20 4.09 16.41 0.5 12 0.5C7.58998 0.5 3.99998 4.09 3.99998 8.5C3.99998 12.91 7.58998 16.5 12 16.5C16.41 16.5 20 12.91 20 8.5ZM6.99998 8.5C6.99998 5.74 9.23997 3.5 12 3.5C14.76 3.5 17 5.74 17 8.5C17 11.26 14.76 13.5 12 13.5C9.23997 13.5 6.99998 11.26 6.99998 8.5ZM14.4 18.5H9.59998C4.35998 18.5 0.0999756 22.76 0.0999756 28C0.0999756 28.83 0.769976 29.5 1.59998 29.5H22.4C23.23 29.5 23.9 28.83 23.9 28C23.9 22.76 19.64 18.5 14.4 18.5ZM3.26998 26.5C3.94998 23.64 6.52998 21.5 9.59998 21.5H14.4C17.47 21.5 20.05 23.64 20.73 26.5H3.26998Z" fill="white"/>
																								</svg>
																						</div>
																						<div className="me-3">
																								<p className="mb-0">Order Total</p>
																								<h4 className="font-w600 mb-0">25.307</h4>
																						</div>
																						<div className="card style-3 m-0  mt-sm-0  mt-3 mt-sm-0 ms-md-0 ms-lg-3">
																								<div className="card-body p-3">
																										<div className="d-flex align-items-center justify-content-between mb-2">
																												<span>Target</span>
																												<h6 className="font-w500 mb-0 data">3.982</h6>
																										</div>
																										<div className="progress default-progress">
																												<div className="progress-bar bg-gradient1 progress-animated" style={{width: '40%', height:'10px' }} role="progressbar">
																														<span className="sr-only">45% Complete</span>
																												</div>
																										</div>
																								</div>
																						</div>
																				</div> */}
                    <div className="d-flex align-items-center">
                      {/* <div className="me-4">
																								<ul className="d-flex"> 
																										<li className="text-nowrap"><svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																										<circle cx="6" cy="6.5" r="4.5" fill="white" stroke="#FC8019" strokeWidth="3"/>
																										</svg>This Month
																										</li>
																								</ul>
																								<h4 className="font-w600 mb-0">1324</h4>
																						</div> */}
                      {/* <div>
																								<ul className="d-flex"> 
																										<li className="text-nowrap"><svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																										<circle cx="6" cy="6.5" r="4.5" fill="white" stroke="#EB5757" strokeWidth="3"/>
																										</svg>Last Month
																										</li>
																								</ul>
																								<h4 className="mb-0 font-w600">1324</h4>
																						</div> */}
                    </div>
                  </div>
                  <Orders isDashboard={isDashboard} />
                  {/* <OrderAreaChart />                                     */}
                </div>
              </div>
            </div>
            {/* <div className="col-xl-12">
              <h4 className="cate-title mb-0">Order History</h4>
              <OrderHistory />
            </div> */}
          </div>
        </div>
        <div className="col-xl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-bx style-3 me-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.26295 22.2499C7.87445 22.2499 7.49046 22.1006 7.19946 21.8096L2.68971 17.2999C2.10246 16.7134 2.10246 15.7609 2.68971 15.1744C3.27696 14.5871 4.22796 14.5871 4.81521 15.1744L8.44145 18.8006L19.3952 11.2819C20.0792 10.8124 21.0152 10.9864 21.4847 11.6704C21.955 12.3551 21.781 13.2904 21.0962 13.7606L9.11346 21.9859C8.85471 22.1629 8.55845 22.2499 8.26295 22.2499Z"
                          fill="#FC8019"
                        />
                        <path
                          d="M8.26295 13.982C7.87445 13.982 7.49046 13.8328 7.19946 13.5418L2.68971 9.03203C2.10246 8.44479 2.10246 7.49304 2.68971 6.90654C3.27696 6.31929 4.22796 6.31929 4.81521 6.90654L8.44145 10.5328L19.3952 3.01404C20.0792 2.54454 21.0152 2.71854 21.4847 3.40254C21.955 4.08729 21.781 5.02254 21.0962 5.49279L9.11346 13.7188C8.85471 13.8958 8.55845 13.982 8.26295 13.982Z"
                          fill="#FC8019"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-w500 mb-0">Total Orders Active</p>
                      <h4 className="cate-title mb-0">{activeOrders}</h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-bx style-3 me-3">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 18.5C4.05 18.5 0 14.45 0 9.5C0 4.55 4.05 0.5 9 0.5C13.95 0.5 18 4.55 18 9.5C18 14.45 13.95 18.5 9 18.5ZM9 2.375C5.1 2.375 1.875 5.6 1.875 9.5C1.875 13.4 5.1 16.625 9 16.625C12.9 16.625 16.125 13.4 16.125 9.5C16.125 5.6 12.9 2.375 9 2.375Z"
                          fill="#FC8019"
                        />
                        <path
                          d="M7.57496 13.0251C7.34996 13.0251 7.04996 12.9501 6.89996 12.7251L5.02496 10.8501C4.64996 10.4751 4.64996 9.87515 5.02496 9.50015C5.39996 9.12515 5.99996 9.12515 6.37496 9.50015L7.57496 10.7001L11.625 6.65015C12 6.27515 12.6 6.27515 12.975 6.65015C13.35 7.02515 13.35 7.62515 12.975 8.00015L8.24996 12.7251C8.02496 12.9501 7.79996 13.0251 7.57496 13.0251Z"
                          fill="#FC8019"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-w500 mb-0">Total Order Redeemed</p>
                      <h4 className="cate-title mb-0">{redeemedOrders}</h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-bx style-3 me-3">
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.00002 0.5C7.21998 0.5 5.47992 1.02784 3.99988 2.01678C2.51983 3.00571 1.36628 4.41132 0.685089 6.05586C0.00389956 7.7004 -0.174331 9.51 0.172937 11.2558C0.520204 13.0017 1.37737 14.6053 2.63605 15.864C3.89472 17.1227 5.49837 17.9798 7.2442 18.3271C8.99004 18.6744 10.7996 18.4961 12.4442 17.8149C14.0887 17.1338 15.4943 15.9802 16.4833 14.5002C17.4722 13.0201 18 11.2801 18 9.50002C18 8.31812 17.7672 7.14779 17.3149 6.05586C16.8627 4.96393 16.1997 3.97177 15.364 3.13604C14.5283 2.30032 13.5361 1.63738 12.4442 1.18509C11.3522 0.732792 10.1819 0.5 9.00002 0.5ZM9.00002 16.7C7.57599 16.7 6.18394 16.2778 4.99991 15.4866C3.81587 14.6955 2.89303 13.571 2.34808 12.2553C1.80312 10.9397 1.66054 9.49203 1.93835 8.09536C2.21617 6.6987 2.9019 5.41578 3.90884 4.40884C4.91578 3.4019 6.1987 2.71616 7.59537 2.43835C8.99203 2.16054 10.4397 2.30312 11.7553 2.84807C13.071 3.39302 14.1955 4.31587 14.9866 5.4999C15.7778 6.68394 16.2 8.07599 16.2 9.50002C16.2 11.4096 15.4415 13.2409 14.0912 14.5912C12.7409 15.9415 10.9096 16.7 9.00002 16.7Z"
                          fill="#FC8019"
                        />
                        <path
                          d="M9.0002 5C8.7615 5 8.53259 5.09482 8.3638 5.2636C8.19502 5.43239 8.1002 5.66131 8.1002 5.9V10.4C8.1002 10.6387 8.19502 10.8676 8.3638 11.0364C8.53259 11.2052 8.7615 11.3 9.0002 11.3C9.2389 11.3 9.46781 11.2052 9.6366 11.0364C9.80538 10.8676 9.9002 10.6387 9.9002 10.4V5.9C9.9002 5.66131 9.80538 5.43239 9.6366 5.2636C9.46781 5.09482 9.2389 5 9.0002 5ZM9.6392 12.479L9.5042 12.362L9.3422 12.281L9.1712 12.2C9.02587 12.1722 8.87593 12.1806 8.73465 12.2246C8.59336 12.2685 8.46509 12.3467 8.3612 12.452C8.28024 12.5349 8.21607 12.6327 8.1722 12.74C8.12033 12.8527 8.09567 12.976 8.1002 13.1C8.10119 13.3361 8.19493 13.5624 8.3612 13.73C8.44843 13.8122 8.54882 13.8791 8.6582 13.928C8.76593 13.9756 8.88242 14.0002 9.0002 14.0002C9.11798 14.0002 9.23447 13.9756 9.3422 13.928C9.45158 13.8791 9.55197 13.8122 9.6392 13.73C9.80547 13.5624 9.89921 13.3361 9.9002 13.1C9.89997 12.9794 9.87548 12.86 9.8282 12.749C9.78236 12.6481 9.71828 12.5566 9.6392 12.479Z"
                          fill="#FC8019"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="font-w500 mb-0">Total Order Expired</p>
                      <h4 className="cate-title mb-0">0</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-sm-6">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="cate-title">Most Ordered Items</h4>
                    <Dropdown>
                      <Dropdown.Toggle as="div" className="btn-link i-false">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                            stroke="#262626"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                            stroke="#262626"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                            stroke="#262626"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu" align="end">
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* pie chart */}
                  {/* <PopularPieChart /> */}
                  {/* pie chart */}
                  <h6 className="font-w700 mb-3">Legend</h6>
                  <div className="d-flex justify-content-between mb-2">
                    <span>
                      <svg
                        className="me-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="16" rx="4" fill="var(--primary)" />
                      </svg>
                      BBQ Burger (27%)
                    </span>
                    <h6>763</h6>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>
                      <svg
                        className="me-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="16" rx="4" fill="#EB5757" />
                      </svg>
                      Prime Rib (50%)
                    </span>
                    <h6>763</h6>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>
                      <svg
                        className="me-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="16" rx="4" fill="#1FBF75" />
                      </svg>
                      French Dip Sandwich (23%)
                    </span>
                    <h6>69</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
