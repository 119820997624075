import Orders from "./Orders";
import { useState, useEffect } from "react";

export default function OrdersPage() {
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    setIsDashboard(false);
  }, []);

  return (
    <Orders isDashboard={isDashboard} />
  );
}