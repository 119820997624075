import React from "react";

import {
  formatError,
  login,
  // runLogoutTimer,
  saveTokenInLocalStorage,
  merchantSignup,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

// export function signupAction(name, email, password, navigate) {
//   return (dispatch) => {
//     signUp(name, email, password)
//       .then((response) => {
//         saveTokenInLocalStorage(response.data);
//         runLogoutTimer(dispatch, response.data.expiresIn * 1000);
//         dispatch(confirmedSignupAction(response.data));
//         navigate("/dashboard");
//       })
//       .catch((error) => {
//         const errorMessage = formatError(error.response.data);
//         dispatch(signupFailedAction(errorMessage));
//       });
//   };
// }

export function MerchantSignupAction(
  business_name,
  email,
  password,
  profile_image,
  phone,
  address,
  city,
  state,
  postal_code,
  navigate
) {
  return (dispatch) => {
    merchantSignup(
      business_name,
      email,
      password,
      profile_image,
      phone,
      address,
      city,
      state,
      postal_code
    )
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate);
        dispatch(confirmedSignupAction(response.data));
        navigate("/merchant/login");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  navigate("/merchant/login");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate);
        dispatch(loginConfirmedAction(response.data));
        navigate("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
