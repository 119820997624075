import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Tab } from "react-bootstrap";

import bgimage from "../../../../images/no-img-avatar.png";
import SelectState from "../../Forms/DropdownLists/SelectState";
import { useDispatch, connect } from "react-redux";
import {
  GetMerchantDataAction,
  UpdateMerchantAction,
} from "../../../../store/actions/MerchantActions";
import { Modal, Button } from "react-bootstrap";
import MerchantSettingsNav from "./MerchantSettingsNav";

const NotificationBlog = [
  { title: "Expecting a slight delay", time: "now" },
  { title: "Your Order has been pickedup by", time: "1 min ago" },
  { title: "Your will arrive at 3:00 - 4:00pm today.", time: "1 hours ago" },
  { title: "Your will arrive at 5:00 - 6:00pm today", time: "3 hours ago" },
];

const inputBlog = [
  { title: "Moblie Apps(s)" },
  { title: "Api" },
  { title: "Moblie web" },
  { title: "Main website" },
  { title: "Tablet" },
  { title: "Other" },
];

const helpblog = [
  { title: "Check your Booking Status" },
  { title: "Checkout of FoodDesk" },
  { title: "Order Tracking Problem" },
  { title: "Login issue" },
];

const policyBlog = [
  { id: "11", title: "Email address" },
  { id: "12", title: "First name and last name" },
  { id: "13", title: "Phone number" },
  { id: "14", title: "Address,state,pin code and city" },
  { id: "15", title: "Social media profile information" },
  { id: "16", title: "Other" },
];

const MerchantSettings = () => {

  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const merchant = useSelector((state) => state.merchant.data);

  useEffect(() => {
    dispatch(GetMerchantDataAction(merchantId));
  }, []);

  //For Image upload in ListBlog
  const [smallModal, setSmallModal] = useState(false);
  const [merchantProfileImage, setMerchantProfileImage] = useState(null);
  const [merchantProfileImagePreview, setMerchantProfileImagePreview] =
    useState(null);
  const [merchantBusinessName, setMerchantBusinessName] = useState(null);
  const [merchantPhone, setMerchantPhone] = useState(null);
  const [merchantEmail, setMerchantEmail] = useState(null);
  const [merchantPassword, setMerchantPassword] = useState(null);
  const [merchantAddress, setMerchantAddress] = useState({
    address: merchant.address,
    city: merchant.city,
    state: merchant.state,
    zip: merchant.postal_code,
  });
  const [merchantLocation, setMerchantLocation] = useState({
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219",
    lat: null,
    long: null,
  });

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const fileHandler = (e) => {
    const value = e.target.files[0];
    setMerchantProfileImage(value);
    // Preview image
    const blob = URL.createObjectURL(value);
    setMerchantProfileImagePreview(blob);
  };

  function RemoveImage() {
    return setMerchantProfileImagePreview(null);
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setMerchantAddress({ ...merchantAddress, [name]: value });
  };

  function handleSave() {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append(
      "business_name",
      merchantBusinessName !== null ? merchantBusinessName : merchant.business_name
    );
    formData.append(
      "email",
      merchantEmail !== null ? merchantEmail : merchant.email
    );
    if (merchantPassword !== null && merchantPassword !== undefined && merchantPassword !== "") {
      formData.append("password", merchantPassword);
    } 
    formData.append(
      "profile_image",
      merchantProfileImage !== null ? merchantProfileImage : merchant.profile_image
    );
    formData.append(
      "phone",
      merchantPhone !== null ? merchantPhone : merchant.phone
    );
    formData.append("address", merchantAddress.address);
    formData.append("city", merchantAddress.city);
    formData.append("state", merchantAddress.state);
    formData.append("postal_code", merchantAddress.zip);
    formData.append("merchantId", merchantId);
    dispatch(UpdateMerchantAction(formData, merchantId));
    const confirmation = document.querySelector(".confirmation-message");

    <Modal className="fade bd-example-modal-sm" size="sm" show={smallModal}>
      <Modal.Header>
        <Modal.Title>Modal title</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setSmallModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>Modal body text goes here.</Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => setSmallModal(false)}>
          Close
        </Button>
        <Button variant="primary">Save changes</Button>
      </Modal.Footer>
    </Modal>;
    confirmation.textContent = "Profile Updated!";
  }

  return (
    <>
      <div className="row">
        {/* <MerchantSettingsNav /> */}
        <Tab.Container defaultActiveKey="Account">
          <div className="col-xl-8">
            <Tab.Content>
              <Tab.Pane eventKey="Account" key="account">
                <form onSubmit={ handleSubmit(handleSave) }>
                  <div className="setting-right">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="mb-4">Account</h3>
                        <p className="fs-18">Photo Profile</p>
                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative d-flex">
                              <div className="avatar-preview">
                                <div
                                // style={{
                                //   backgroundImage:
                                //     "url(" + file
                                //       ? URL.createObjectURL(file)
                                //       : bgimage + ")",
                                // }}
                                ></div>
                                <img
                                  src={
                                    merchantProfileImagePreview
                                      ? merchantProfileImagePreview
                                      : bgimage
                                  }
                                  alt={
                                    merchantProfileImage
                                      ? merchantProfileImage.name
                                      : null
                                  }
                                />
                              </div>
                              <div className="change-btn d-flex align-items-center flex-wrap">
                                <input
                                  type="file"
                                  id="merchant-image-upload"
                                  {...register("profile_image")}
                                  onChange={ fileHandler }
                                  className="form-control"
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="merchant-image-upload"
                                  className="dlab-upload"
                                >
                                  Choose File
                                </label>
                                <button
                                  className="btn remove-img ms-2"
                                  onClick={() => RemoveImage()}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-sm-6">
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Business Name
                              </label>
                              <input
                                type="text"
                                {...register("merchant-business-name")}
                                onChange={(e) =>
                                  setMerchantBusinessName(e.target.value)
                                }
                                className="form-control"
                                id="merchant-business-name"
                                defaultValue={merchant.business_name}
                              />
                            </div>
                            {/* <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                name="merchant-username"
                                onChange={handleAddressChange}
                                className="form-control"
                                id="merchant-username"
                                placeholder="Jordan Nico"
                              />
                            </div> */}
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                {...register("merchant-email")}
                                onChange={(e) => setMerchantEmail(e.target.value)}
                                className="form-control"
                                id="merchant-email"
                                defaultValue={merchant.email}
                              />
                            </div>
                            <div className="setting-input">
                              <p className="fs-18 mb-2">Your Location</p>
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219"
                                style={{
                                  border: "0",
                                  width: "100%",
                                  minHeight: "100%",
                                  marginBottom: "-8px",
                                }}
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-6">
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputnumber"
                                className="form-label"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                {...register("merchant-phone")}
                                onChange={(e) => setMerchantPhone(e.target.value)}
                                className="form-control"
                                id="merchant-phone"
                                defaultValue={merchant.phone}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="form-label"
                              >
                                Update Password
                              </label>
                              <input
                                type="password"
                                {...register("merchant-password")}
                                defaultValue={merchant.password}
                                onChange={(e) => setMerchantPassword(e.target.value)}
                                className="form-control"
                                id="merchant-password"
                                placeholder="********"
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Business Address
                              </label>
                              <input
                                type="text"
                                {...register("address")}
                                onChange={handleAddressChange}
                                className="form-control"
                                id="merchant-address"
                                defaultValue={merchant.address}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                {...register("city")}
                                onChange={handleAddressChange}
                                className="form-control"
                                id="merchant-city"
                                defaultValue={merchant.city}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                State
                              </label>
                              <SelectState
                                name={"state"}
                                defaultValue={merchant.state}
                                onChange={handleAddressChange}
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                Zip Code
                              </label>
                              <input
                                type="text"
                                {...register("zip")}
                                pattern="[0-9]{5}"
                                onChange={handleAddressChange}
                                className="form-control"
                                defaultValue={merchant.postal_code}
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary float-end w-50 btn-md"
                            >
                              Save
                            </button>
                            <p className="confirmation-message text-success fw-bold"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
              <Tab.Pane eventKey="Notification">
                <div className="setting-right">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4>Notification</h4>
                    </div>
                    <div className="card-body">
                      {NotificationBlog.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex align-items-center" key={index}>
                            <svg
                              className="me-3 notification-svg"
                              width="39"
                              height="31"
                              viewBox="0 0 39 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M25.125 21.125L26.9952 23.2623C27.6771 24.0417 28.8616 24.1206 29.6409 23.4387C29.7036 23.3839 29.7625 23.325 29.8173 23.2623L31.6875 21.125H36.375C35.2848 26.5762 30.4985 30.5 24.9393 30.5H14.0607C8.5015 30.5 3.71523 26.5762 2.625 21.125H25.125Z"
                                fill="#FC8019"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M36.375 9.875H2.625C3.71523 4.4238 8.5015 0.5 14.0607 0.5H24.9393C30.4985 0.5 35.2848 4.4238 36.375 9.875Z"
                                fill="#FC8019"
                              />
                              <path
                                opacity="0.3"
                                d="M36.375 13.625H2.625C1.58947 13.625 0.75 14.4645 0.75 15.5C0.75 16.5355 1.58947 17.375 2.625 17.375H36.375C37.4105 17.375 38.25 16.5355 38.25 15.5C38.25 14.4645 37.4105 13.625 36.375 13.625Z"
                                fill="#fda65e"
                              />
                            </svg>
                            <span>{item.title}</span>
                            <span className="ms-auto">{item.time}</span>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Security">
                <div className="setting-right">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-3">
                          <div className="contact-title">
                            <h4 className="mb-xl-0 mb-2">Contact-info</h4>
                          </div>
                        </div>
                        <div className="col-xl-8">
                          <div className="setting-input mb-3">
                            <label className="form-label">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="Jordan Nico"
                            />
                          </div>
                          <div className="setting-input">
                            <label className="form-label">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              defaultValue="ordanico@mail.com"
                            />
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="area-affected mb-xl-0 mb-2">
                            <h4 className="mb-0">Areas affedted</h4>
                            <span>choose one or more</span>
                          </div>
                        </div>
                        <div className="col-xl-8">
                          <div className="row">
                            {inputBlog.map((item, ind) => (
                              <div className="col-xl-6 col-sm-4" key={ind}>
                                <div className="form-check form-switch d-flex align-items-center mb-3">
                                  <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    role="switch"
                                  />
                                  <span>{item.title}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <h4 className="mb-xl-0 mb-2">Description</h4>
                        </div>
                        <div className="col-xl-8">
                          <div>
                            <div className="setting-input">
                              <textarea className="form-control" rows="3"></textarea>
                            </div>
                          </div>
                          <Link to={"#"} className="btn btn-primary btn-md">
                            Save Setting
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Payment">
                <div className="setting-right">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">Payble Email id</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <input
                            type="email"
                            className="form-control"
                            defaultValue="ordanico@mail.com"
                          />
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">Currency</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <select
                            className="form-control default-select wide ms-0"
                            defaultValue={""}
                          >
                            <option value="">Australian dollar</option>
                            <option value="1">United States dollar</option>
                            <option value="2">Indian Rupee</option>
                            <option value="3">Yen</option>
                            <option value="3">Riyal</option>
                            <option value="3">Taiwan dollar</option>
                            <option value="3">U.A.E. Dirham</option>
                          </select>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">Need Payer Address</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <div className="d-flex aligs-items-center face-id">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input me-2"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input me-2"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">Payment Type</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <div className="d-flex aligs-items-center face-id">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input me-2"
                                type="radio"
                                name="flexRadio"
                                id="flexRadioDefault3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault3"
                              >
                                Online
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input me-2"
                                type="radio"
                                name="flexRadio"
                                id="flexRadioDefault4"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                Offline
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">item</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <select
                            className="form-control default-select wide ms-0"
                            defaultValue={""}
                          >
                            <option value="">Selected Field</option>
                            <option value="1">Pizza</option>
                            <option value="2">Burger</option>
                            <option value="3">Bakery</option>
                            <option value="3">chicken</option>
                            <option value="3">Patties</option>
                          </select>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">Amount</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <select
                            className="form-control default-select wide ms-0"
                            defaultValue={""}
                          >
                            <option value="">Selected Field</option>
                            <option value="1">100</option>
                            <option value="2">200</option>
                            <option value="3">500</option>
                            <option value="3">1000</option>
                            <option value="3">2000</option>
                          </select>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">quantity</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <select
                            className="form-control default-select wide ms-0"
                            defaultValue={""}
                          >
                            <option value="">Selected Field</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="3">4</option>
                            <option value="3">5</option>
                          </select>
                        </div>
                        <div className="col-xl-4 mb-3">
                          <h6 className="mb-0">language</h6>
                        </div>
                        <div className="col-xl-8 mb-3">
                          <select
                            className="form-control default-select wide ms-0 mb-3"
                            defaultValue={""}
                          >
                            <option value="">Selected Field</option>
                            <option value="1">English(u.k)</option>
                            <option value="2">English(usa)</option>
                            <option value="3">Hindi</option>
                            <option value="3">Japanese</option>
                            <option value="3">Chinese</option>
                          </select>
                        </div>
                        <button className="btn btn-primary w-auto ms-auto me-3">
                          Save Setting{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Help">
                <div className="setting-right">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div>
                          <h4>How we can Help You ?</h4>
                          <div className="input-group search-area2 w-100 style-1 mb-3">
                            <span className="input-group-text p-0">
                              <Link to={"#"}>
                                <svg
                                  className="me-1"
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                    fill="#FC8019"
                                  />
                                </svg>
                              </Link>
                            </span>
                            <input
                              type="text"
                              className="form-control p-0"
                              placeholder="Search Help....."
                            />
                          </div>
                          <h4>What's new</h4>
                          {helpblog.map((item, ind) => (
                            <div
                              className="d-flex align-items-center justify-content-between mb-1"
                              key={ind}
                            >
                              <h6 className="font-w500 mb-0">
                                <Link to={"#"} className="text-priamry">
                                  {item.title}
                                </Link>
                              </h6>
                              <div className="form-check form-switch d-flex align-items-center">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  role="switch"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Pricavy">
                <div className="setting-right">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4">
                          <h6>Privacy policy Generator</h6>
                          <span>
                            Let select the paltforms where your Privacy policy will
                            be used on
                          </span>
                        </div>
                        <div className="col-xl-8">
                          <h6>
                            What kind of personal information do you collect from
                            user?
                          </h6>
                          <p className="mb-2">Click all that apply</p>
                          {policyBlog.map((item, ind) => (
                            <div
                              className="d-flex align-items-center mb-1"
                              key={ind}
                            >
                              <div className="form-check style-3 me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`policy${item.id}`}
                                />
                              </div>
                              <span>{item.title}</span>
                            </div>
                          ))}
                        </div>
                        <div className="col-xl-4"></div>
                        <div className="col-xl-8">
                          <h6>
                            Select addtional information you collect from a device
                            (moblie/tablet)
                          </h6>
                          <p className="mb-2">
                            Skip selecting an option if none apply to you
                          </p>
                          <div className="d-flex align-items-center mb-1">
                            <div className="form-check style-3 me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="checkAll-6"
                              />
                            </div>
                            <span>Location</span>
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            <div className="form-check style-3 me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="checkAll-7"
                              />
                            </div>
                            <span>Contactlist</span>
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            <div className="form-check style-3 me-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="checkAll-8"
                              />
                            </div>
                            <span>Camera</span>
                          </div>
                        </div>
                        <div className="col-xl-4"></div>
                        <div className="col-xl-8 face-id">
                          <h6>
                            Do you use tracking and/or analytics tools,such as Google
                            Analytics
                          </h6>
                          <p className="mb-2">
                            Skip selecting an option if none apply to you
                          </p>
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault5"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault5"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault6"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault6"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default MerchantSettings;
