import review1 from "./../../../../images/popular-img/review-img/pic-1.jpg";
import bgimage from "../../../../images/no-img-avatar.png";
import API_CONFIG from "../../../../apiConfig";
import { useEffect } from "react";

export default function OrdersItems(props) {
  const { orderDetails } = props;
  const baseUrl = API_CONFIG.baseUrl;
  
  return (
    <>
      {orderDetails.map((item, index) => (
        <div key={index} className="d-flex align-items-center mb-4">
          <img className="me-3" src={`${baseUrl}/${item.item.image}`} alt="" />
          <div>
            <h4 className="font-w600 text-nowrap mb-0">
              {item.item ? item.item.title : "title"}
            </h4>
            <p className="mb-0">
              {item.item.description ? item.item.description : "description"}
            </p>
          </div>
          <h4 className="text-primary mb-0 ms-auto">
            +{item.item.price ? item.item.price : "Price"}
          </h4>
        </div>
      ))}
    </>
  );
}
