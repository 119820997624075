import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { auth } from "./reducers/AuthReducer";
import { merchant } from "./reducers/MerchantReducer";
import { menuItems } from "./reducers/MenuItemReducer";
import { orders } from "./reducers/OrdersReducers";
import { users } from "./reducers/UserReducer";

const persistConfig = {
  key: "root",
  storage,
};

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth,
  merchant,
  menuItems,
  orders,
  users,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composeEnhancers(middleware));

export const persistor = persistStore(store);
