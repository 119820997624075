import axios from "axios";
const api = {
  prod: 'https://api.nm.bdgwebdesign.com/public/api/v1',
  dev: 'http://localhost/nuncmitto/nuncmitto_api/public/api/v1',
};

const url = {
  prod: 'https://nm.bdgwebdesign.com',
  dev: 'http://localhost',
};

const isDevEnv = process.env.NODE_ENV === 'development';

const axiosInstance = axios.create({
  baseURL: !isDevEnv
  ? api.prod
  : api.dev,
  
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': {baseURL: isDevEnv ? `${url.dev}` : `${url.prod}`},
    'Accept': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const { store } = await import("../store/store");
  const state = store.getState();
  const token = state.auth.auth.access_token;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export default axiosInstance;