import { useState } from "react";
import OrdersItems from "./OrdersItems";
import { useSelector } from "react-redux";
import API_CONFIG from "../../../../apiConfig";

import bgimage from "../../../../images/no-img-avatar.png";
import { useEffect } from "react";

export default function OrdersDetails(props) {
  const baseURL = API_CONFIG.baseUrl;
  const merchantImage = useSelector((state) => state.merchant.data.profile_image);
  const { selectedOrder, orderDetails, totalPrice, formatDate } = props;  

  return(
    <div className="col-xl-12" id="order-details">
      <div className="card border-0">
        <h4 className="cate-title mb-sm-3 mb-2 mt-xl-0 mt-3">Order Details</h4>
        <div className="card h-auto">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between border-bottom flex-wrap">
              <div className="mb-4">
                <h4 className="font-w500">Order #{selectedOrder.id}</h4>
                <span>{formatDate(selectedOrder.created_at)}</span>
              </div>
              <div className="orders-img d-flex mb-4">
                <img src={ merchantImage ? `${baseURL}/${merchantImage}` : bgimage } alt="Profile Image" />
                <div>
                  <h6 className="font-w500">
                    UserName: {selectedOrder.user_id}
                  </h6>
                  <span>User since 2020</span>
                </div>
              </div>
            </div>
            <div className="row pb-2"></div>
            <div className="order-menu style-1 mt-3">
              <h4>Order Items</h4>
              <OrdersItems orderDetails={orderDetails} />
            </div>
            <hr style={{ opacity: "0.7" }} />
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="font-w500 mb-0">Total</h4>
              <h4 className="cate-title text-primary">
                ${totalPrice}
              </h4>
            </div>
          </div>
        </div>
        {/* <div className="text-end">
          <button
            onClick={() => deleteItemHandler()}
            className="btn btn-outline-danger me-sm-4 me-2"
          >
            Delete Order
          </button>
          <button
            onClick={() => editItemHandler()}
            className="btn btn-primary"
          >
            Edit Order
          </button>
        </div> */}
      </div>
    </div>
  );
}