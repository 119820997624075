import React, { lazy, useState, useReducer, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AddItemModal from "./AddItemModal";
import EditItemModal from "./EditItemModal";
import { useDispatch, useSelector } from "react-redux";

import MenuItemsList from "./MenuItemsList";
import PageTitle from "../../../layouts/PageTitle";
import API_CONFIG from "../../../../apiConfig";

import {
  GetMenuItemsAction,
  DeleteMenuItemAction,
  UpdateMenuItemAction,
} from "../../../../store/actions/MenuItemsActions";

// DropdownBlog component is for the 3 dots that show Edit and Delete options
const DropdownBlog = ({ editItem, deleteItem }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  return (
    <>
      <Dropdown className="">
        <Dropdown.Toggle
          as="div"
          className="btn-link i-false"
          style={{ cursor: "pointer" }}
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-primary pe-auto" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="text-danger" onClick={() => deleteItem()}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const MenuItems = () => {
  const dispatch = useDispatch();

  // Store data
  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const menuItemsList = useSelector((state) => state.menuItems.menuItems.data);
  const state = useSelector((state) => state);
  const baseUrl = API_CONFIG.baseUrl;

  // Component State
  const [filteredItems, setfilteredItems] = useState([]);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState("");
  const [addedNewItem, setAddedNewItem] = useState(false);
  const [editedItem, setEditedItem] = useState(false);
  const [deletedItem, setDeletedItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Use Effects

  // When Items are changed, added, or deleted, get updated list of items
  useEffect(() => {
    setIsLoading(true);
    dispatch(GetMenuItemsAction());
  }, [addedNewItem, editedItem, deletedItem]);

  useEffect(() => {
    if (
      menuItemsList !== undefined &&
      menuItemsList.length > 0 &&
      menuItemsList !== null
    ) {
      setfilteredItems(menuItemsList.filter((item) => item.merchant_id === merchantId));
    }
    setAddedNewItem(false);
    setEditedItem(false);
    setDeletedItem(false);
    setIsLoading(false);
  }, [menuItemsList]);

  // Functions

  // Update Modal open/close state
  function addItemModalToggle() {
    return setShowAddItemModal(!showAddItemModal);
  }

  function editItemModalToggle() {
    return setShowEditItemModal(!showEditItemModal);
  }

  // Set Active/Inactive Item
  function handleClick(isActive, id) {
    let temp = filteredItems.map((data) => {
      if (id === data.id) {
        if (isActive === 1) {
          updateItemActive(0, id);
          return { ...data, active: 0 };
        } else {
          updateItemActive(1, id);
          return { ...data, active: 1 };
        }
      }
      return data;
    });
    setfilteredItems(temp);
  }

  function updateItemActive(isActive, itemId) {
    const formData = new FormData();
    formData.append("active", isActive);
    formData.append("_method", "put");
    dispatch(UpdateMenuItemAction(formData, itemId));
  }

  function handleDelete(id) {
    dispatch(DeleteMenuItemAction(id));
    setDeletedItem(true);
  }

  function handleEdit(item) {
    setItemToEdit(item);
    editItemModalToggle();
  }
  
  return (
    <>
      <Tab.Container defaultActiveKey="Grid">
        <PageTitle />
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="input-group search-area2 style-1">
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Search menu items..."
            />
          </div>
          <button
            type="button"
            className="btn btn-primary mt-3 mt-sm-0"
            onClick={() => addItemModalToggle()}
          >
            Add Menu Item
          </button>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="Grid">
            <div className="row">
              {isLoading === false && filteredItems.map((item, index) => (
                <div className="col-xl-3 col-xxl-4 col-sm-6" key={index}>
                  <div className="card dishe-bx b-hover style-1">
                    <div className="card-body pb-0 pt-3">
                      <div className="text-center mb-2">
                        { item.image && <img src={`${baseUrl}/${item.image}`} alt="" /> }

                      </div>
                      <div className="border-bottom pb-3">
                        {/* <h4 className="font-w500 mb-1">Fast Food Rest</h4> */}
                        <h4 className="text-center">{item.title}</h4>
                      
                        {/* 
                        
                        // 5 star reviews 
                        <div className="d-flex align-items-center">
                          <svg
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 0.500031L9.79611 6.02789H15.6085L10.9062 9.4443L12.7023 14.9722L8 11.5558L3.29772 14.9722L5.09383 9.4443L0.391548 6.02789H6.20389L8 0.500031Z"
                              fill="#FC8019"
                            />
                          </svg>
                          <p className="font-w500 mb-0 px-2">5.0</p>
                          <svg
                            className="me-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="2" cy="2.50003" r="2" fill="#C4C4C4" />
                          </svg>
                          <p className=" font-w500 mb-0">1k+ Reviews</p>
                        </div> */}
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-2">

                        <div>
                          {/* <Link to={"#"}>
                                <h4>{item.title}</h4>
                              </Link> */}
                          <h3 className="text-primary text-center">{item.price}</h3>
                        </div>

          
                      <div className="row">
                      <div className="common d-flex align-items-end justify-content-between">
                        <div className="ml-1">
                          <div className="c-pointer" onClick={() => handleEdit(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen text-primary pe-auto" viewBox="0 0 16 16">
                              <path d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                            </svg>
                          </div>
                        </div>

                        <div className="ml-1">
                        <DropdownBlog
                        deleteItem={() => handleDelete(item.id)}
                      />
                        </div>
                        <div className="">
                          <div
                            className={`plus c-pointer ${
                              item.active === 1 ? "active" : ""
                            }`}
                            onClick={() => handleClick(item.active, item.id)}
                          >
                            <div className="sub-bx"></div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="d-flex align-items-center justify-content-xl-between justify-content-center flex-wrap pagination-bx">
                <div className="mb-sm-0 mb-3 pagination-title">
                  <p className="mb-0">
                    <span>Items { filteredItems && filteredItems.length }</span> of <span>{filteredItems.length}</span>
                  </p>
                </div>
                <nav>
                  <ul className="pagination pagination-gutter">
                    <li className="page-item page-indicator">
                      <Link to={"#"} className="page-link">
                        <i className="la la-angle-left"></i>
                      </Link>
                    </li>
                    <li className="page-item active">
                      <Link to={"#"} className="page-link">
                        1
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        2
                      </Link>
                    </li>

                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        3
                      </Link>
                    </li>
                    <li className="page-item page-indicator">
                      <Link to={"#"} className="page-link">
                        <i className="la la-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <AddItemModal
        modalToggle={addItemModalToggle}
        showModal={showAddItemModal}
        addedItem={setAddedNewItem}
      />
      <EditItemModal
        modalToggle={editItemModalToggle}
        showModal={showEditItemModal}
        editedItem={setEditedItem}
        item={itemToEdit}
      />
    </>
  );
};

export default MenuItems;
