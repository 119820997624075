import React from "react";
import { addMenuItem, updateMerchant } from "../../services/MerchantService";
import { getMerchant } from "../../services/MerchantService";
import { formatError } from "../../services/AuthService";

export const MERCHANT_CREATE_ACTION = "[create action] created merchant";
export const GET_MERCHANT_ACTION = "[get action] received merchant";
export const UPDATE_MERCHANT_ACTION = "[update action] confirmed update";
export const DELETE_MERCHANT_ACTION = "[update action] confirmed update";
export const ADD_MENU_ITEM_ACTION = "[create action] created menu item";

export function GetMerchantDataAction(merchandId) {
  return (dispatch) => {
    getMerchant(merchandId)
      .then((response) => {
        dispatch(GetMerchantConfirmedAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function UpdateMerchantAction(formData, Id) {
  return async (dispatch) => {
    try {
      const response = await updateMerchant(formData, Id);
      dispatch(UpdateMerchantConfirmedAction(response.data));
    } catch (error) {
      const errorMessage = formatError(error.message);
      console.log(errorMessage);
    }
  };
}

export function CreateMerchantConfirmedAction(data) {
  return {
    type: MERCHANT_CREATE_ACTION,
    payload: data,
  };
}

export function GetMerchantConfirmedAction(data) {
  return {
    type: GET_MERCHANT_ACTION,
    payload: data,
  };
}

export function UpdateMerchantConfirmedAction(data) {
  return {
    type: UPDATE_MERCHANT_ACTION,
    payload: data,
  };
}

export function AddMenuItemAction(data) {
  return {
    type: ADD_MENU_ITEM_ACTION,
    payload: data,
  };
}
