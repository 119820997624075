import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import profile from "../../../../images/profile/profile.png";
import bgimage from "../../../../images/no-img-avatar.png";
import PageTitle from "../../../layouts/PageTitle";

import cover from "../../../../images/profile/cover.jpg";
// import { useAsyncDebounce } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { GetMerchantDataAction } from "../../../../store/actions/MerchantActions";
import API_CONFIG from "../../../../apiConfig";

// import {
//   DoubleRight,
//   CircleRight,
//   CircleExclamation,
//   EarningIcon,
//   PointIcon,
//   TimerIcon,
//   MinGrapg,
//   AvgGraph,
// } from "../Drivers/SvgIcons";

// const statusBlog = [
//   { icons: DoubleRight, title: "Finished", number: "932" },
//   { icons: CircleRight, title: "Delivered", number: "1,032" },
//   { icons: CircleExclamation, title: "Canceled", number: "102" },
// ];

// const EarningBlog = [
//   { number: "11.240", IconClass: "success" },
//   { number: "10.654", IconClass: "primary" },
// ];

// const performance = [
//   { iconblog: TimerIcon, title: "Performance", percent: "50%" },
//   { iconblog: MinGrapg, title: "Min. Performance", percent: "80%" },
//   { iconblog: AvgGraph, title: "Avg. Performance", percent: "75%" },
// ];

const MerchantProfile = () => {
  const [merchantName, setMerchantName] = useState("");
  const [merchantEmail, setMerchantEmail] = useState("");
  const [merchantPhone, setMerchantPhone] = useState("");
  const [merchantLocation, setMerchantLocation] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825624477!2d75.65046970649679!3d26.88544791796718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C+Rajasthan!5e0!3m2!1sen!2sin!4v1500819483219"
  );

  const merchantId = useSelector((state) => state.auth.auth.merchant_id);
  const name = useSelector((state) => state.merchant.data.business_name);
  const email = useSelector((state) => state.merchant.data.email);
  const phone = useSelector((state) => state.merchant.data.phone);
  const state = useSelector((state) => state);
  const merchant = useSelector((state) => state.merchant.data);
  const baseUrl = API_CONFIG.baseUrl;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetMerchantDataAction(merchantId));
  }, []);

  useEffect(() => {
    name ? setMerchantName(name) : setMerchantName("");
    email && setMerchantEmail(email);
    phone && setMerchantPhone(phone);
  }, []);

  return (
    <>
      <div className="row">
        <PageTitle />
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0 mt-5">
            <div className="profile-head">
              <div className="photo-content ">
                {/* <img src={cover} className="cover-photo rounded" /> */}
                {/* <div className="cover-photo rounded"></div> */}
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={ merchant.profile_image ? `${baseUrl}/${merchant.profile_image}` : `${bgimage}`}
                    className="img-fluid rounded-circle merchant-profile-image"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{merchant.business_name}</h4>
                    <p>Business Name</p>
                  </div>
                  <div className="profile-email px-4 pt-2">
                    <h4 className="text-muted mb-0">{merchant.email}</h4>
                    <p>Email</p>
                  </div>
                  <div className="profile-email px-4 pt-2">
                    <h4 className="text-muted mb-0">{merchant.phone}</h4>
                    <p>Phone</p>
                  </div>
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item href="/merchant-settings" className="dropdown-item">
                        <i className="fa fa-user-circle text-primary me-2" />
                        Edit profile
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* <div className="setting-input pb-4">
                <p className="fs-18 mb-2">Location</p>
                <iframe
                  src={merchantLocation}
                  style={{
                    border: "0",
                    width: "100%",
                    minHeight: "100%",
                    marginBottom: "-8px",
                  }}
                  allowFullScreen
                ></iframe>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantProfile;
