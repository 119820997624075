import React from "react";
import {
  addMenuItem,
  getMenuItems,
  getMerchantMenuItems,
  updateMenuItem,
  deleteMenuItem,
} from "../../services/MenuItemsService";
import { formatError } from "../../services/AuthService";

export const ADD_MENU_ITEM_ACTION = "[create action] created menu item";
export const GET_MENU_ITEM_ACTION = "[get action] retrieved menu item";
export const GET_MERCHANT_MENU_ITEM_ACTION = "[get action] retrieved menu items";
export const UPDATE_MENU_ITEM_ACTION = "[update action] updated menu item";
export const DELETE_MENU_ITEM_ACTION = "[delete action] deleted menu item";

export function AddMenuItemsAction(formData) {
  return (dispatch) => {
    addMenuItem(formData)
      .then((response) => {
        dispatch(AddMenuItemConfirmed(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function GetMenuItemsAction() {
  return (dispatch) => {
    getMenuItems()
      .then((response) => {
        dispatch(GetMenuItemConfirmed(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  };
}

export function GetMerchantMenuItemsAction(id) {
  return (dispatch) => {
    getMerchantMenuItems(id)
      .then((response) => {
        dispatch(GetMerchantMenuItemConfirmed(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(errorMessage);
      });
  };
}

export function UpdateMenuItemAction(formData, id) {
  return (dispatch) => {
    updateMenuItem(formData, id)
      .then((response) => {
        dispatch(UpdateMenuItemConfirmed(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.error(errorMessage);
      });
  };
}

export function DeleteMenuItemAction(id) {
  return (dispatch) => {
    deleteMenuItem(id)
      .then((response) => {
        dispatch(DeleteMenuItemConfirmed(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        console.log(error(errorMessage));
      });
  };
}

export function AddMenuItemConfirmed(data) {
  return {
    type: ADD_MENU_ITEM_ACTION,
    payload: data,
  };
}

export function GetMenuItemConfirmed(data) {
  return {
    type: GET_MENU_ITEM_ACTION,
    payload: data,
  };
}

export function GetMerchantMenuItemConfirmed(data) {
  return {
    type: GET_MERCHANT_MENU_ITEM_ACTION,
    payload: data,
  };
}

export function UpdateMenuItemConfirmed(data) {
  return {
    type: UPDATE_MENU_ITEM_ACTION,
    payload: data,
  };
}

export function DeleteMenuItemConfirmed(data) {
  return {
    type: DELETE_MENU_ITEM_ACTION,
    payload: data,
  };
}
